import {Column, Spacer, Text} from 'native-base';
import {EmailFormField, GenderSelectField} from '@ui/components/forms/Fields';
import {Keyboard, Platform, TextInput, TouchableWithoutFeedback} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import {UserEditRequest, UserEditResponse} from '@shared/models/user';
import {firstNameValidations, lastNameValidations, phoneValidations} from '@ui/components/forms/validation';
import BirthdayComponent from './BirthdayComponent';
import FloatingLabelInput from '@ui/components/FloatingLabelInput';
import {HpcInputField} from '@ui/features/auth/hcp/AuthHcpScreen';
import NotFound from '@ui/components/NotFound';
import SaveButton from '@ui/components/buttons/save/SaveButton';
import SettingsSection from '@ui/components/settings/SettingsSection';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';
import {subYears} from 'date-fns';
import {toNumber} from 'lodash';
import useCallable from '@ui/hooks/useCallable';
import useCrouton from '@ui/hooks/useCrouton';
import {useRouter} from 'solito/router';
import useTrackButtonClick from '@ui/helpers/analytics/analytics-hooks';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    onFinish?: () => void;
    hasHcp?: boolean;
    children?: React.ReactNode;
}

const currentDate = subYears(new Date(), 18);

const PersonalDetailsSettingsSection = (props: Props) => {
    const {onFinish, hasHcp = true, children} = props;
    const {user} = useUser();
    const [editUser, isEditUserLoading] = useCallable<UserEditRequest, UserEditResponse>('httpsUserUpdate-default');
    const {t} = useTranslation();
    const crouton = useCrouton();
    const {back} = useRouter();

    const trackButtonClick = useTrackButtonClick();

    const [email, setEmail] = useState(user?.email ?? '');
    const [firstName, setFirstName] = useState(user?.firstName ?? '');
    const [lastName, setLastName] = useState(user?.lastName ?? '');
    const [username, setUsername] = useState(user?.username ?? '');
    const [phone, setPhone] = useState(user?.phone ?? '');
    const [birthday, setBirthday] = useState(user?.birthday ? getDatabaseDate(user.birthday) : currentDate);
    const [gender, setGender] = useState(user?.gender ?? '');

    const [hcp, setHcp] = useState(user?.sports?.golf?.hcp ? user.sports.golf.hcp.toString().replace('-', '') : '');
    const [negative, setNegative] = useState(false);

    const emailRef = useRef<TextInput>();
    const firstNameRef = useRef<TextInput>();
    const lastNameRef = useRef<TextInput>();
    const usernameRef = useRef<TextInput>();
    const phoneRef = useRef<TextInput>();

    const isButtonDisabled = [email, firstName, lastName, username].find(x => x.trim().length === 0) !== undefined;

    if(!user) {
        return <NotFound />;
    }

    useEffect(() => {
        const userHcp = user?.sports?.golf?.hcp;

        if(hcp !== '' && userHcp !== undefined) {

            setHcp(userHcp.toString().replace('-', ''));
            setNegative(userHcp < 0);
        }
    }, [user]);

    const getHcpValue = () => {
        const number = toNumber(hcp);
        const hcpValue = negative ? -number : number;
        return hcpValue;
    };

    const onPress = async() => {
        if(isButtonDisabled) {
            return;
        }

        const validations = [...firstNameValidations(firstName, t), ...lastNameValidations(lastName, t), ...phoneValidations(phone, t)];

        if(validations.length > 0) {
            crouton.show({
                title: t('actions.warning'),
                message: validations.join('\n'),
                preset: 'error',
            });
            return;
        }

        const hcpValue = hcp.length > 0 ? getHcpValue() : undefined;
        const hasBirthday = birthday !== currentDate;

        const response = await editUser({
            id: user.id,
            email,
            username,
            firstName,
            lastName,
            ...(hcpValue && {hcp: hcpValue}),
            ...(phone.length > 0 && {phone}),
            ...(hasBirthday && {birthday: birthday?.toISOString()}),
            ...(gender.length > 0 && {gender}),
        });

        if(response) {
            if(response.emailUpdated) {
                crouton.show({
                    title: t('actions.success'),
                    message: t('auth.profile.emailUpdated'),
                });
            } else {
                crouton.show({
                    title: t('actions.success'),
                    message: t('auth.profile.updated'),
                });
            }
            if(onFinish) {
                onFinish();
            } else {
                back();
            }
        }
    };

    return (
        <SettingsSection title={t('auth.profile.personalDetails')} _column={{flex: 1}} _childrenColumn={{flex: 1}}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} disabled={Platform.OS === 'web'}>
                <Column space='4' flex={1} safeAreaBottom>
                    <EmailFormField defaultValue={email} onChangeText={setEmail} inputRef={emailRef} onSubmitEditing={() => firstNameRef.current?.focus()} />

                    <FloatingLabelInput
                        isRequired
                        label={t('auth.firstName.text')}
                        defaultValue={firstName}
                        onChangeText={setFirstName}
                        textContentType='name'
                        keyboardType='name-phone-pad'
                        autoCorrect={false}
                        autoComplete='name'
                        returnKeyType='next'
                        maxLength={60}
                        onSubmitEditing={() => lastNameRef.current?.focus()}
                        blurOnSubmit={false}
                        inputRef={firstNameRef}
                    />

                    <FloatingLabelInput
                        isRequired
                        label={t('auth.lastName.text')}
                        defaultValue={lastName}
                        onChangeText={setLastName}
                        textContentType='familyName'
                        keyboardType='name-phone-pad'
                        autoCorrect={false}
                        autoComplete='name-family'
                        returnKeyType='next'
                        maxLength={60}
                        onSubmitEditing={() => usernameRef.current?.focus()}
                        blurOnSubmit={false}
                        inputRef={lastNameRef}
                    />

                    <FloatingLabelInput
                        isRequired
                        label={t('auth.username.text')}
                        defaultValue={username}
                        onChangeText={setUsername}
                        textContentType='familyName'
                        keyboardType='name-phone-pad'
                        autoCorrect={false}
                        autoComplete='name-family'
                        returnKeyType='next'
                        maxLength={60}
                        onSubmitEditing={() => phoneRef.current?.focus()}
                        blurOnSubmit={false}
                        inputRef={usernameRef}
                    />

                    <FloatingLabelInput
                        label={t('auth.phone.text')}
                        defaultValue={phone}
                        onChangeText={setPhone}
                        textContentType='telephoneNumber'
                        keyboardType='phone-pad'
                        autoCorrect={false}
                        autoCapitalize="none"
                        autoComplete='tel'
                        returnKeyType='next'
                        maxLength={20}
                        blurOnSubmit={false}
                        inputRef={phoneRef}
                    />

                    {hasHcp &&
                        <Column space='2'>
                            <Text variant='medium'>{t('auth.hcp.text')}</Text>

                            <HpcInputField
                                hcp={hcp}
                                setValue={setHcp}
                                onPress={onPress}
                                negative={negative}
                                setNegative={setNegative}
                                _textInput={{
                                    fontSize: 16,
                                    lineHeight: 20,
                                    fontWeight: 500,
                                }}
                                borderWidth={1}
                                borderRadius={4}
                                borderColor="blacks.300"
                                pr='4'
                                pl={negative ? 2 : 0}
                            />
                        </Column>
                    }

                    <BirthdayComponent
                        value={birthday ?? new Date()}
                        type='date'
                        open
                        onChange={setBirthday}
                        maximumDate={new Date()}
                        dateText={t('auth.birthday.text')}
                        _container={{padding: 0}}
                    />

                    <GenderSelectField
                        value={gender}
                        setValue={setGender}
                    />

                    {children}

                    <Spacer />

                    <SaveButton
                        isDisabled={isButtonDisabled}
                        isLoading={isEditUserLoading}
                        onPress={trackButtonClick('User Profile Updated', {user_id: user.id, email}, onPress)}
                    />
                </Column>
            </TouchableWithoutFeedback>
        </SettingsSection>
    );
};

export default PersonalDetailsSettingsSection;
